import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

$('#slick-home').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  fade: true,
  infinite: true,
  arrows: true,
  autoplay: false,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  centerMode: false,
  autoplaySpeed: 6000,
  appendArrows: '#slick-arrows',
  prevArrow: '<button class="arrow-prev"><span class="sprite arrow-large-prev"></span></button>',
  nextArrow: '<button class="arrow-next"><span class="sprite arrow-large-next"></span></button>',
});

setTimeout(() => {
  $('#slick-home .slick-active').addClass('animation');
}, 250);

$('#slick').on('afterChange', () => {
  $('#slick-home .slick-active').addClass('animation');
});

$('#slick').on('beforeChange', () => {
  $('#slick-home .slick-active').removeClass('animation');
});

$('#slick-logos').slick({
  slidesToShow: 5,
  slidesToScroll: 2,
  dots: false,
  fade: false,
  infinite: true,
  arrows: false,
  autoplay: true,
  adaptiveHeight: true,
  cssEase: 'ease-in-out',
  speed: 400,
  variableWidth: true,
  centerMode: false,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
});

$('#slick-product').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  fade: false,
  arrows: false,
  autoplay: true,
  adaptiveHeight: false,
  cssEase: 'ease-in-out',
  speed: 400,
  autoplaySpeed: 6000,
  responsive: [{
    breakpoint: 1200,
    settings: {},
  },
  {
    breakpoint: 991,
    settings: {},
  },
  {
    breakpoint: 576,
    settings: {
      autoplay: false,
    },
  },
  ],
});

$('#slick-product-nav .button').on('click', function () {
  const number = $(this).attr('data-trigger');
  $('#slick-product').slick('slickGoTo', parseInt(number - 1), false);
});
