import 'evo-calendar';
import 'evo-calendar/evo-calendar/css/evo-calendar.css';

$('#calendar').evoCalendar({
  language: 'fr',
  format: 'dd MM yyyy',
  titleFormat: 'MM',
  sidebarToggler: true,
  firstDayOfWeek: 1,
  sidebarDisplayDefault: false,
  eventDisplayDefault: false,
  calendarEvents: window.events,
});

$('#calendar-button-sidebar').on('click', () => {
  const isOpen = $('#calendar-button-sidebar').hasClass('open');
  if (isOpen) {
    $('#calendar-button-sidebar').removeClass('open');
  } else {
    $('#calendar-button-sidebar').addClass('open');
  }
  $('#calendar').evoCalendar('toggleSidebar', !isOpen);
});

$('#calendar').on('selectMonth', () => {
  $('#calendar-button-sidebar').removeClass('open');
  $('#calendar').evoCalendar('toggleSidebar', false);
});

$('#calendar').on('selectDate', (_, newDate) => {
  const isNewDateExist = window.events.find((elm) => elm.date === newDate);
  if (isNewDateExist) {
    $('#calendar').evoCalendar('toggleEventList', true);
  } else {
    $('#calendar').evoCalendar('toggleEventList', false);
  }
});
