const navigationMobile = () => {
  $('#button--mobile').on('click', () => {
    $('body').toggleClass('menu-open');
    $('#navigation-main').toggle();
  });
};

const navigationSize = () => {
  const position = $(window).scrollTop();
  if (position > 70) {
    $('body').addClass('header-small');
  } else {
    $('body').removeClass('header-small');
  }
  $(window).on('scroll', (e) => {
    if ($(e.currentTarget).scrollTop() > 70) {
      $('body').addClass('header-small');
    } else {
      $('body').removeClass('header-small');
    }
  });
};

const navigationDropdown = () => {
  const viewport = $(window).width();
  if (viewport < 576) {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      $(e.currentTarget).next().toggle();
      return false;
    });
  } else {
    $('#navigation-main li[data-navsub] > a').on('click', (e) => {
      const navSub = $(e.currentTarget).parent();
      const isFocus = navSub.hasClass('focus');
      $('#navigation-main li[data-navsub]').removeClass('focus');
      if (!isFocus) {
        navSub.addClass('focus');
      }
      e.stopPropagation();
      return false;
    });
    $('html').on('click', () => {
      $('#navigation-main li[data-navsub]').removeClass('focus');
    });
  }
};

const scrollTop = () => {
  const domElm = $('#button--scroll-top');
  domElm.fadeOut(0);
  $(window).on('scroll', (e) => {
    if ($(e.currentTarget).scrollTop() > 220) {
      domElm.fadeIn(300);
    } else {
      domElm.fadeOut(300);
    }
  });
  domElm.on('click', () => {
    $('html, body').animate({
      scrollTop: 0,
    }, '500');
    return false;
  });
};

// change background-color of slick container
$('#slick-home-wrapper').css('background-color', $('#slick-slide00').find('.category').css('background-color'));
$('#slick-home-wrapper .link').css('color', $('#slick-slide00').find('.category').css('color'));
$( document ).ready(function() {
    $('.slick-dots li').click(function(){
        var slide = $(this).find('button').attr('aria-controls');
        var background_color = $('#'+slide).find('.category').css('background-color');
        var color = $('#'+slide).find('.category').css('color');
        $('#slick-home-wrapper').css('background-color', background_color);
        $('#slick-home-wrapper .link').css('color', color);
    });
});

navigationMobile();
navigationDropdown();
navigationSize();
scrollTop();
